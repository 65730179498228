import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const BookingPage = () => {
  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    head.appendChild(script);

    return () => {
      head.removeChild(script);
    };
  }, []);

  return (
    <div>
         <Divider sx={{color: 'black'}} className='Divider' textAlign="center">
      <Typography className='about' variant='h4' sx={{ textAlign: 'center', padding: '10px', color: '#6559cc' }}>Book a meeting</Typography>
      </Divider>
       <iframe
        src="https://calendly.com/devalter"
        width="100%"
        height="800px"
        frameBorder="0"
        title="Calendly Booking Page"
      ></iframe>
     
    </div>
  );
};

export default BookingPage;
