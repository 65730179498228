import React, { useState, useEffect } from 'react';
import './style.css';
import { FaLinkedinIn, FaTwitter, FaInstagram } from 'react-icons/fa'; // Import icons from Font Awesome

const TopHeader = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  return (
    <div className={`top-header ${isVisible ? '' : 'hidden'}`}>
      <div className="social-icons">
        {/* Add social media icons */}
        <a href="https://www.linkedin.com/company/devalter-io" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
        <span className="social-icon-divider"></span> {/* Add a divider */}
        <a href="https://twitter.com/devalter_io" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
        <span className="social-icon-divider"></span> {/* Add a divider */}
        <a href="https://www.instagram.com/yourinstagrampage" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
      </div>
      <div className="phone-number">
        Talk to us today: <a href="tel:+447807886318">+447807886318</a>
      </div>
    </div>
  );
};

export default TopHeader;

