import React from 'react';
import Home from './pages/Home';
import Aboutus from './components/about/Aboutus';
import BookingPage from './components/BookingPage';
import Contact from './components/contact';
import Nav from './components/nav';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {Helmet} from "react-helmet";
import TopHeader from './components/topheader';



function App() {
  return (
    <div className='App'>
    <Router>
        <Helmet>
          <title>DevAlter</title>
        </Helmet>
        <TopHeader/>
       <Nav/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<Aboutus/>} />
        <Route path="/booking" element={<BookingPage/>} />
        <Route path='/contact' element={<Contact/>}></Route>
      </Routes>
    </Router>
    </div>
  );
}

export default App;
