import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import 'aos/dist/aos.css';
import AOS from 'aos';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';
import { BorderBottom } from '@mui/icons-material';
import './style.css';

const AboutUs = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
        <Typography className='about' variant='h4' sx={{ textAlign: 'center', padding: '20px', color: '#6559cc' }}>Welcome to DevAlter, your trusted IT partner</Typography>
      <Box className='' sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', gap: '10px', padding: '10px' }}>
        
        <div className='aboutUS'>
          <Typography variant="subtitle1" color="text.secondry">
            For comprehensive cyber security services, innovative cloud solutions, reliable IT support, expert IT consulting, and cutting-edge web development. We are dedicated to empowering businesses with technology solutions that drive growth, efficiency, and security.
            At Devalter, we understand the critical importance of safeguarding your digital assets in today's interconnected world. Our cyber security services are designed to fortify your organisation's defenses, providing robust protection against evolving threats. From implementing multi-layered security measures to conducting thorough risk assessments.
          </Typography>
        </div>
      </Box>
    </div>
  );
};

export default AboutUs;
