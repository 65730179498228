import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import './style.css'
import { Link } from 'react-router-dom';


const Nav = () => {


    return(
        <>
        <div className='container-fluid'>
        <nav className="navbar navbar-expand-lg navbar-light bg-light d-flex">
            <a className="navbar-brand" href="/">DevAlter</a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
        <div className="navbar-nav ml-auto">
            <a className="nav-link" href="#">IT Consulting</a>
            <a className="nav-link" href="#">Web Development</a>
          <a className="nav-link" href="#">IT Support</a>
          <a className="nav-link" href="#">Cyber Security</a>
        </div>
        <Link className='link' to='contact'>
         <button className='nav-item ml-auto btn btn-dark' type='button' >Contact Us</button>
         </Link>
      </div>
     
    </nav>
    </div>
  </>
    )
     
}

export default Nav