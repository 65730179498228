import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import AboutUs from '../components/about/Aboutus';
import Herogrid from '../components/home';
import Services from '../components/services';
import Footer from '../components/footer';
import Contact from '../components/contact';




const Home = () => {
  return (
    <div>
      <Herogrid/>
      <AboutUs/>
      <Services/>
      <Footer/>
    </div>
  );
};

export default Home;
